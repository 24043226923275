//import { Component } from "react"
//import pbjs from 'prebid.js';

// //Load Bid Adapaters
// import 'prebid.js/modules/aolBidAdapter'; //One by AOL
// import 'prebid.js/modules/openxBidAdapter'; //OpenX
// import 'prebid.js/modules/tripleliftBidAdapter'; //Triplelift
// import 'prebid.js/modules/yieldoneBidAdapter'; //YeildOne
// //import 'prebid.js/modules/yahoosspBidAdapter'; //Yahoo SSP
// import 'prebid.js/modules/appnexusBidAdapter'; //Appnexus
// import 'prebid.js/modules/sovrnBidAdapter'; //Sovrn
// import 'prebid.js/modules/teadsBidAdapter'; //Teads
// import 'prebid.js/modules/adagioBidAdapter'; //Adagio
// import 'prebid.js/modules/ixBidAdapter'; //Index Exchange
// import 'prebid.js/modules/sharethroughBidAdapter'; //Share Through
// import 'prebid.js/modules/onetagBidAdapter'; //OneTag
// import 'prebid.js/modules/criteoBidAdapter'; //Criteo
// import 'prebid.js/modules/pubmaticBidAdapter'; //Pubmatic
// import 'prebid.js/modules/justpremiumBidAdapter'; //Just Premium
// //import 'prebid.js/modules/'; //One Fifty Media
// import 'prebid.js/modules/adfBidAdapter'; //Ad Form

// //Anayltic Adapters
// import 'prebid.js/modules/adagioAnalyticsAdapter'; //Adagio Analytics

// //Recommended modules
// import 'prebid.js/modules/consentManagement'; //Consent Mangement
// import 'prebid.js/modules/gdprEnforcement'; //GDPR Enforcement

// //User ID Modules
// import 'prebid.js/modules/lotamePanoramaIdSystem'; //Lotame ID
// import 'prebid.js/modules/id5IdSystem'; //ID5 ID
// import 'prebid.js/modules/identityLinkIdSystem'; //Identity Link ID

// //General Modules
// import 'prebid.js/modules/currency'; //currency
// import 'prebid.js/modules/priceFloors'; //Price Floors

import { CPMEvents } from "./cpm-events";
import { boonsRefresh } from "./boons-refresh";

export const fetchHeaderBids = (apstagSlots, adUnits, bidTimeout) => {
  const abtest = localStorage.getItem("abtest");
  // const isRefreshTestV1 = abtest === `ab.${process.env.GATSBY_SHORT_DOMAIN}.e`;

  // declare bidders
  const bidders = ["a9", "prebid"];

  // create a requestManager to keep track of bidder state to determine when to send ad server
  // request and what apstagSlots to request from the ad server
  let requestManager = {
    adserverRequestSent: false,
  };

  // loop through bidder array and add the bidders to the request manager
  bidders.forEach(function (bidder) {
    requestManager[bidder] = false;
  });

  // return true if all bidders have returned
  function allBiddersBack() {
    var allBiddersBack =
      // if length is equal to bidders, all bidders are back
      bidders
        // get the booleans from the object
        .map(function (bidder) {
          return requestManager[bidder];
        })
        // get rid of false values - indicates that the bidder has responded
        .filter(Boolean).length === bidders.length;

    return allBiddersBack;
  }

  // handler for header bidder responses
  function headerBidderBack(bidder) {
    // return early if request to adserver is already sent
    if (requestManager.adserverRequestSent === true) {
      return;
    }
    // flip bidder back flag
    if (bidder === "a9") {
      requestManager.a9 = true;
    } else if (bidder === "prebid") {
      requestManager.prebid = true;
    }
    // if all bidders are back, send the request to the ad server
    if (allBiddersBack()) {
      sendAdserverRequest();
    }
  }

  // actually get ads from GAM
  function sendAdserverRequest() {
    // return early if request already sent
    if (requestManager.adserverRequestSent === true) {
      return;
    }

    // flip the boolean that keeps track of whether the adserver request was sent
    requestManager.adserverRequestSent = true;
    // flip pbjs boolean to signal to pbjs the ad server has already been called
    window.pbjs.adserverRequestSent = true;

    // flip boolean for adserver request to avoid duplicate requests
    requestManager.sendAdserverRequest = true;

    // set bid targeting and make ad request to GAM
    window.googletag.cmd.push(function () {
      window.apstag.setDisplayBids();
      window.pbjs.setTargetingForGPTAsync();
      window.googletag.pubads().refresh();
    });
  }
  /* 
  if (isRefreshTestV1) {
    boonsRefresh();
  }
 */
  function requestBids(apstagSlots, adUnits, bidTimeout) {
    // eslint-disable-next-line no-use-before-define
    var PREBID_TIMEOUT = PREBID_TIMEOUT || 2700;

    if (PREBID_TIMEOUT > bidTimeout) {
      PREBID_TIMEOUT = bidTimeout - 300;
    }

    // fetch apstag bids, set bid targting, then call headerBidderBack
    // to get the ads for the first time
    window.apstag.fetchBids(
      {
        slots: apstagSlots,
        timeout: bidTimeout,
      },
      function (bids) {
        headerBidderBack("a9");
      }
    );

    // request bids from prebid
    window.pbjs.que.push(() => {
      window.pbjs.onEvent("auctionEnd", function () {
        if (typeof CPMEvents === "undefined") {
          return;
        }
        console.log("auctionEnd_1");
        CPMEvents();
      });

      window.pbjs.onEvent("bidWon", function (data) {
        if (typeof CPMEvents === "undefined") {
          return;
        }

        console.log("bidWon_1");
        CPMEvents();
      });

      //Check for initial ads
      let initialAds = window.pbjs.adUnits.find(
        (ad) => ad.code === "LeaderBelowTitle"
      );
      if (!initialAds) {
        window.pbjs.addAdUnits(adUnits);
      }

      window.pbjs.bidderSettings = {
        standard: {
          storageAllowed: true,
        },
        adagio: {
          storageAllowed: true,
        },
        appnexus: {
          storageAllowed: true,
        },
        criteo: {
          storageAllowed: true,
        },
        ix: {
          storageAllowed: true,
        },
        justpremium: {
          storageAllowed: true,
        },
        openx: {
          storageAllowed: true,
        },
        onetag: {
          storageAllowed: true,
        },
        sovrn: {
          storageAllowed: true,
        },
        triplelift: {
          storageAllowed: true,
        },
        yieldone: {
          storageAllowed: true,
        },
        sharethrough: {
          storageAllowed: true,
        },
        taboola: {
          storageAllowed: true,
        },
        rise: {
          storageAllowed: true,
        },
        ogury: {
          storageAllowed: true,
        },
        vidazoo: {
          storageAllowed: true,
        },
        smartadserver: {
          storageAllowed: true,
        },
        districtm: {
          bidCpmAdjustment: function (bidCpm) {
            return bidCpm * 0.85;
          },
          storageAllowed: true,
        },
        adform: {
          bidCpmAdjustment: function (bidCpm) {
            return bidCpm * 0.86;
          },
          storageAllowed: true,
        },
      };

      const customConfigPriceSetup = {
        buckets: [
          {
            precision: 2,
            max: 5,
            increment: 0.01,
          },
          {
            precision: 2,
            max: 20,
            increment: 0.05,
          },
          {
            precision: 2,
            max: 70,
            increment: 0.5,
          },
        ],
      };

      window.pbjs.enableAnalytics([
        {
          provider: "adagio",
        },
      ]);

      window.pbjs.setConfig({
        userSync: {
          syncsPerBidder: 50,
        },
        consentManagement: {
          gdpr: {
            cmpApi: "iab",
            timeout: 10000, // in milliseconds
            allowAuctionWithoutConsent: true,
          },
        },
        useBidCache: true,
        enableSendAllBids: true,
        priceGranularity: customConfigPriceSetup,
        currency: {
          // enables currency feature
          adServerCurrency: "EUR",
        },
        targetingControls: {
          allowTargetingKeys: [
            "BIDDER",
            "AD_ID",
            "PRICE_BUCKET",
            "SIZE",
            "DEAL",
            "SOURCE",
            "FORMAT",
            "UUID",
            "CACHE_ID",
            "CACHE_HOST",
            "ADOMAIN",
          ],
        },
      });

      window.pbjs.requestBids({
        timeout: PREBID_TIMEOUT,
        adUnits: adUnits,
        bidsBackHandler: function (bidResponses) {
          headerBidderBack("prebid");
        },
      });

      const floorTargeting = (cpm, buckets) => {
        let targeting = undefined;
        function inRange(x, min, max) {
          return x > min && x <= max;
        }
        buckets.forEach((bucket, i) => {
          let min = i > 0 ? buckets[i - 1].max : 0;
          if (inRange(cpm, min, bucket.max)) {
            targeting = (Math.floor(cpm / bucket.inc) * bucket.inc).toFixed(2);
          }
        });
        return targeting
          ? targeting
          : buckets[buckets.length - 1].max.toFixed(2);
      };

      window.pbjs.onEvent("auctionEnd", function (arg) {
        window.googletag.cmd.push(function () {
          let gptSlots = window.googletag.pubads().getSlots();
          arg.adUnitCodes.forEach(function (adUnitCode) {
            let gptAdUnit = gptSlots.filter(
              (slot) => slot.getSlotElementId() === adUnitCode
            )[0];
            let winner = window.pbjs.getHighestCpmBids(adUnitCode);
            let priceBuckets = [
              { max: 5, inc: 0.05 },
              { max: 10, inc: 0.1 },
            ];
            let gam_val =
              winner.length > 0
                ? floorTargeting(winner[0].cpm, priceBuckets)
                : 0;
            gptAdUnit.setTargeting("prkv", Math.min(gam_val, 10.0).toFixed(2));
          });
        });
      });
    });
  }

  requestBids(apstagSlots, adUnits, bidTimeout);

  window.setTimeout(function () {
    sendAdserverRequest();
  }, bidTimeout);
};
