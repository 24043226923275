import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  const abtest = window.localStorage.getItem("abtest");

  // Define Ad unit names
  // Original
  let RightColumnUnitName = "NEXT_RightColumnHalfPage1";
  let LeftColumnUnitName = "NEXT_LeftColumnHalfPage";
  let MPUTopRightUnitName = "NEXT_MPUTopRight";
  let LeaderBelowTitleUnitName = "NEXT_Leader_BellowTitle";
  // Domain/device Specific
  if (abtest === "ab.if.com.b") {
    RightColumnUnitName = "DESK_RightColumn";
    LeftColumnUnitName = "DESK_LeftColumn";
    MPUTopRightUnitName = "MOB_MPUTopRight";
    LeaderBelowTitleUnitName = "DESK_LeaderBelowTitle";
    if (window.screen.width < 812) {
      LeaderBelowTitleUnitName = "MOB_LeaderBelowTitle";
    }
  }

  // Define Ad Unit Pathways
  // Original
  let RightColumnPathway = "NEXT_RightColumnHalfPage1";
  let LeftColumnPathway = "NEXT_LeftColumnHalfPage";
  let MPUTopRightPathway = "NEXT_MPUTopRight";
  let LeaderBelowTitlePathway = "NEXT_Leader_BellowTitle";
  // Domain/device Specific
  if (abtest === "ab.ed.com.y") {
    RightColumnPathway = "ED/DESK_RightColumn";
    LeftColumnPathway = "ED/DESK_LeftColumn";
    MPUTopRightPathway = "ED/DESK_MPUTopRight";
    LeaderBelowTitlePathway = "ED/DESK_LeaderBelowTitle";
    if (window.screen.width < 812) {
      MPUTopRightPathway = "ED/MOB_MPUTopRight";
      LeaderBelowTitlePathway = "ED/MOB_LeaderBelowTitle";
    }
  }

  // prettier-ignore
  return [
    {
      condition:
        !checkUtmSourceStartsWith("twtr_") &&
        !checkUtmSourceStartsWith("yahoo_"),
      id: "LeaderBelowTitle",
      name: LeaderBelowTitleUnitName,
      path: `/76730613/${LeaderBelowTitlePathway}`,
      sizes:
        window.screen.width < 812
          ? [
              [300, 250],
              [336, 280],
              [320, 100],
            ]
          : [[728, 90]],
      bidders: [
        {
          bidder: "onetag",
          params: {
            pubId: "654b33df39ee492",
          },
        },
        //   {
        //     bidder: 'adagio',
        //     params: {
        //         organizationId: '1067',
        //         site: 'trendscatchers-com',
        //         adUnitElementId: 'LeaderBelowTitle',
        //         environment: 'desktop',
        //         placement: `${LeaderBelowTitlePathway}`
        //     }
        // },
        { bidder: "adform", params: { mid: 1707868 } },
        // //{bidder: 'appnexus', params: {placementId: '20942283'}},
        // {bidder: 'criteo', params: {networkId: 8147}},
        // {bidder: 'ix', params: {siteId: 623506, size: [728, 90]}},
        // //{bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        // //{ bidder: 'districtm',  params: {  placementId: 16005275 } },
        // //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
        { bidder: 'openx', params: {unit: '559526092', delDomain: 'lqr-d.openx.net'}},
        // {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        // //{bidder: 'pubmatic',params: {publisherId: '159940', adSlot: 'com_desktop_next_Leader_bellowTitle'}},
        // //{ bidder: 'richaudience', params: { pid: 'QReieyrtGC', supplyType: 'site' }},
        { bidder: "sovrn", params: { tagid: 1141206 } },
        // { bidder: 'teads', params: { pageId: '113724', placementId: '135706'} },
        // {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_Leader_bellowTitle_Prebid"}},
        {
          bidder: "vidazoo",
          params: {
            cId: "647870b8e6c93f675397f31e",
            pId: "59ac17c192832d0011283fe3",
            bidFloor: "0.05",
          },
        },
        {
          bidder: "smartadserver",
          params: { siteId: 597050, pageId: 1826582, formatId: 116604 },
        },
        // {bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
        // {bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        // {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        {
          bidder: "sharethrough",
          params: { pkey: "HAmb0i2H3Ugl5ruW4hch5kUk" },
        },
        {
          bidder: "medianet",
          params: { cid: "8CUJF5HOT", crid: 273381276 },
        },
      ],
      mobile_bidders: [
        {
          bidder: "onetag",
          params: {
            pubId: "654b33df39ee492",
          },
        },
        //   {
        //     bidder: 'adagio',
        //     params: {
        //         organizationId: '1067',
        //         site: 'trendscatchers-com',
        //         adUnitElementId: 'LeaderBelowTitle',
        //         environment: 'mobile',
        //         placement: `${LeaderBelowTitlePathway}`
        //     }
        // },
        { bidder: "adform", params: { mid: 1707874 } },
        // //{bidder: 'appnexus', params: {placementId: '20942283'}},
        // {bidder: 'criteo', params: {networkId: 8147}},
        // //{ bidder: 'districtm',  params: { placementId: 16005275 } },
        // //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
        // {bidder: 'ix', params: {siteId: 623528, size: [300, 250]}},
        // {bidder: 'ix', params: {siteId: 623528, size: [336, 280]}},
        // {bidder: 'ix', params: {siteId: 623528, size: [320, 100]}},
        // //{bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        // {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        {bidder: 'openx', params: {unit: '559526093', delDomain: 'lqr-d.openx.net'}},
        // // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_mobile_next_Leader_bellowTitle'}},
        // //{ bidder: 'richaudience', params: { pid: '6yQQzMZSfj', supplyType: 'site' }},
        { bidder: "sovrn", params: { tagid: 1141242 } },
        { bidder: "sovrn", params: { tagid: 1142125 } },
        { bidder: "sovrn", params: { tagid: 1142124 } },
        // //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
        // {bidder: 'teads', params: {pageId: '121182', placementId: '135710'}},
        // {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_Leader_bellowTitle_Prebid"}},
        {
          bidder: "vidazoo",
          params: {
            cId: "647870b8e6c93f675397f31e",
            pId: "59ac17c192832d0011283fe3",
            bidFloor: "0.05",
          },
        },
        {
          bidder: "smartadserver",
          params: { siteId: 597050, pageId: 1826582, formatId: 116604 },
        },
        // {bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
        // {bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        // {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        {
          bidder: "sharethrough",
          params: { pkey: "HhMuz8KZ9yHklM4ylIohlYcn" },
        },
        {
          bidder: "sharethrough",
          params: { pkey: "LB8S9uS8i44rDGJ1XvnZttlk" },
        },
        {
          bidder: "medianet",
          params: { cid: "8CUJF5HOT", crid: 273381276 },
        },
      ],
    },
    {
      condition: window.screen.width < 812,
      id: "MPUTopRight",
      name: MPUTopRightUnitName,
      path: `/76730613/${MPUTopRightPathway}`,
      sizes: [
        [300, 250],
        [336, 280],
        [320, 100],
      ],
      mobile_bidders: [
        {
          bidder: "onetag",
          params: {
            pubId: "654b33df39ee492",
          },
        },
        //   {
        //     bidder: 'adagio',
        //     params: {
        //         organizationId: '1067',
        //         site: 'trendscatchers-com',
        //         adUnitElementId: 'MPUTopRight',
        //         environment: 'mobile',
        //         placement: `${MPUTopRightPathway}`
        //     }
        // },
        { bidder: "adform", params: { mid: 1707876 } },
        // //{bidder: 'appnexus', params: {placementId: '20942284'}},
        // {bidder: 'criteo', params: {networkId: 8147}},
        // //{ bidder: 'districtm', params: { placementId: 16005279 } },
        // //{ bidder: 'districtmDMX', params: { dmxid: 367605, memberid : 101868 } },
        // {bidder: 'ix', params: {siteId: 623512, size: [300, 250]}},
        // {bidder: 'ix', params: {siteId: 623512, size: [336, 280]}},
        // {bidder: 'ix', params: {siteId: 623512, size: [320, 100]}},
        // //{bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        // {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        {bidder: 'openx', params: {unit: '559526097', delDomain: 'lqr-d.openx.net'}},
        // // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_mobile_next_MPUTopRight'}},
        // //{ bidder: 'richaudience', params: { pid: 'hoS2bUVNvb', supplyType: 'site' }},
        { bidder: "sovrn", params: { tagid: 1141245 } },
        { bidder: "sovrn", params: { tagid: 1142137 } },
        { bidder: "sovrn", params: { tagid: 1142138 } },
        // //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
        // {bidder: 'teads', params: {pageId: '121182', placementId: '135711'}},
        // {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_MPUTopRight_Prebid"}},
        {
          bidder: "vidazoo",
          params: {
            cId: "647870b8e6c93f675397f31e",
            pId: "59ac17c192832d0011283fe3",
            bidFloor: "0.05",
          },
        },
        {
          bidder: "smartadserver",
          params: { siteId: 597050, pageId: 1826582, formatId: 116604 },
        },
        // {bidder: 'taboola', params: { tagId: 'MPUTopRight', publisherId: '1490051' }},
        // {bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        // {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        {
          bidder: "sharethrough",
          params: { pkey: "12mSUQPtQNbqfx5CupMjOMb6" },
        },
        {
          bidder: "sharethrough",
          params: { pkey: "m6xEDWQGZNhtCIKIoGTaEmDO" },
        },
        {
          bidder: "medianet",
          params: { cid: "8CUJF5HOT", crid: 339895831 },
        },
      ],
    },
    {
      condition: window.screen.width > 812,
      id: "RightColumn",
      name: RightColumnUnitName,
      path: `/76730613/${RightColumnPathway}`,
      sizes:
        window.screen.width > 1440
          ? [
              [300, 600],
              [160, 600],
            ]
          : [[160, 600]],
      bidders: [
        {
          bidder: "onetag",
          params: {
            pubId: "654b33df39ee492",
          },
        },
        //     {
        //       bidder: 'adagio',
        //       params: {
        //           organizationId: '1067',
        //           site: 'trendscatchers-com',
        //           adUnitElementId: 'RightColumn',
        //           environment: 'desktop',
        //           placement: `${RightColumnPathway}`
        //       }
        //   },
        { bidder: "adform", params: { mid: 1707872 } },
        { bidder: "sovrn", params: { tagid: 1141225 } },
        { bidder: "sovrn", params: { tagid: 1141223 } },
        { bidder: "sovrn", params: { tagid: 1141222 } },
        //  // {bidder: 'appnexus', params: {placementId: '20942290'}},
        //   {bidder: 'criteo', params: {networkId: 8147}},
        //   //{ bidder: 'districtm', params: { placementId: 16005296 } },
        //   //{ bidder: 'districtmDMX', params: { dmxid: 367608, memberid : 101868 } },
        //   {bidder: 'ix', params: {siteId: 623502, size: [160, 600]}},
        //   //{bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        //   {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
          {bidder: 'openx', params: {unit: '559526101', delDomain: 'lqr-d.openx.net'}},
        //   // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_desktop_next_MPUTopRight1'}},
        //   //{ bidder: 'richaudience', params: { pid: 'HOpAKTXHmc', supplyType: 'site' }},
        //   {bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
        //   { bidder: 'triplelift', params: { inventoryCode: "TrendscatchersCOM_desktop_NEXT_RightColumnHalfPage_Prebid"} },
        {
          bidder: "vidazoo",
          params: {
            cId: "647870b8e6c93f675397f31e",
            pId: "59ac17c192832d0011283fe3",
            bidFloor: "0.05",
          },
        },
        {
          bidder: "smartadserver",
          params: { siteId: 597050, pageId: 1826582, formatId: 116604 },
        },
        //   {bidder: 'taboola', params: { tagId: 'RightColumn', publisherId: '1490051' }},
        //   {bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        //   {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        {
          bidder: "sharethrough",
          params: { pkey: "yTVwMegcdatCVef9AOaKHQA3" },
        },
        {
          bidder: "sharethrough",
          params: { pkey: "IOmOBRPahxfuB6Ea2hfxED8v" },
        },
        {
          bidder: "medianet",
          params: { cid: "8CUJF5HOT", crid: 548276061 },
        },
      ],
    },
    {
      condition:
        window.screen.width > 812 &&
        !checkUtmSourceStartsWith("twtr_") &&
        !checkUtmSourceStartsWith("yahoo_"),
      id: "LeftColumn",
      name: LeftColumnUnitName,
      path: `/76730613/${LeftColumnPathway}`,
      sizes:
        window.screen.width > 1440
          ? [
              [300, 600],
              [160, 600],
            ]
          : [[160, 600]],
      bidders: [
        {
          bidder: "onetag",
          params: {
            pubId: "654b33df39ee492",
          },
        },
        //   {
        //     bidder: 'adagio',
        //     params: {
        //         organizationId: '1067',
        //         site: 'trendscatchers-com',
        //         adUnitElementId: 'LeftColumn',
        //         environment: 'desktop',
        //         placement: `${LeftColumnPathway}`
        //     }
        // },
        { bidder: "adform", params: { mid: 1707904 } },
        // {bidder: 'criteo', params: {networkId: 347220}},
        // {bidder: 'ix', params: {siteId: 697578, size: [160, 600]}},
        // {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: "sovrn", params: { tagid: 1141207 } },
        { bidder: "sovrn", params: { tagid: 1141209 } },
        { bidder: "sovrn", params: { tagid: 1141208 } },
        // //{bidder: 'appnexus', params: {placementId: '22129020'}},
        // {bidder: 'openx', params: {unit: '543986374', delDomain: 'lqr-d.openx.net'}},
        // // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'trendscatchers.com_desktop_NEXT_LeftColumnHalfPage'}},
        // { bidder: 'triplelift', params: { inventoryCode: "TrendscatchersCOM_desktop_NEXT_LeftColumnHalfPage_Prebid"} },
        // {bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
        {
          bidder: "vidazoo",
          params: {
            cId: "647870b8e6c93f675397f31e",
            pId: "59ac17c192832d0011283fe3",
            bidFloor: "0.05",
          },
        },
        {
          bidder: "smartadserver",
          params: { siteId: 597050, pageId: 1826582, formatId: 116604 },
        },
        // {bidder: 'taboola', params: { tagId: 'LeftColumn', publisherId: '1490051' }},
        // {bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        // {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        {
          bidder: "sharethrough",
          params: { pkey: "EEUnds0wbzaEvVcYEwDiBd1V" },
        },
        {
          bidder: "medianet",
          params: { cid: "8CUJF5HOT", crid: 548276061 },
        },
      ],
    },
  ];
}
